import { Controller } from "@hotwired/stimulus";

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export default class extends Controller {
  static targets = [
    "datasetControls",
    "canvas",
    "disabledMessage",
    "mapUser",
    "miniMap",
    "miniMapPositionIndicator",
    "sizer",
    "svg",
    "totalUsersCountLabel",
    "transformer",
    "visibleUsersCountLabel",
  ];
  static values = {
    disabledMessage: Object,
    display: String,
    users: Array,
    usersVisible: Boolean,
  };

  connect() {
    window.map = this;
    this.users = JSON.parse(this.data.get("users"));
    this.usersAlreadyShown = [];
    this.startingPoint = JSON.parse(this.data.get("startingPoint"));
    this.transform = {
      x: 0,
      y: 0,
      scale: 1,
    };
    this.panningState = {
      isPanning: false,
      passedThreshold: false,
      startX: undefined,
      startY: undefined,
      x: undefined,
      y: undefined,
    };
    this.isZoomedOut = false;
    this.perPage = parseInt(this.data.get("perPage"));

    this.shuffle();
    if (this.hasTotalUsersCountLabelTarget) {
      this.totalUsersCountLabelTarget.textContent = this.users.length;
    }

    this.scrollToStartingPoint({ duration: 0 }).then(() => {
      this.dispatch("ready");
    });
  }

  destroy() {
    this.unsetZoom();
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  adjustMiniMapZoom({
    to = "current",
    duration = 1000,
    delay = 0,
    easing = "ease-in-out",
  } = {}) {
    let top, left, width, height;
    if (this.isZoomedOut) {
      top = 0;
      left = 0;
      width = 1;
      height = 1;
    } else if (
      typeof to === "object" &&
      ("number" || typeof to.y === "number")
    ) {
      width = this.dimensions.sizer.width / this.dimensions.transformer.width;
      height =
        this.dimensions.sizer.height / this.dimensions.transformer.height;
      top = (to.y / this.dimensions.transformer.height) * -1;
      left = (to.x / this.dimensions.transformer.width) * -1;
    } else {
      width = this.dimensions.sizer.width / this.dimensions.transformer.width;
      height =
        this.dimensions.sizer.height / this.dimensions.transformer.height;
      top = (this.transform.y / this.dimensions.transformer.height) * -1;
      left = (this.transform.x / this.dimensions.transformer.width) * -1;
    }

    const translation = `translate3d(${left * 100}%, ${top * 100}%, 0)`;
    const scale = `scale(${width}, ${height})`;
    const borderXWidth = 2 * (1 / width);
    const borderYWidth = 2 * (1 / height);
    return this.miniMapPositionIndicatorTarget.animate(
      [
        {
          transform: `${translation} ${scale}`,
          borderWidth: `${borderYWidth}px ${borderXWidth}px`,
        },
      ],
      { duration, delay, easing, fill: "forwards" }
    ).finished;
  }

  changeDisplay(e) {
    this.displayValue = e.target.value;
  }

  clampXY({ x, y }) {
    return {
      x: clamp(
        x,
        this.dimensions.transformer.width * -1 + this.dimensions.sizer.width,
        0
      ),
      y: clamp(
        y,
        this.dimensions.transformer.height * -1 + this.dimensions.sizer.height,
        0
      ),
    };
  }

  hideControls({ duration = 300, delay = 0, easing = "linear" } = {}) {
    this.datasetControlsTarget.animate([{ opacity: 0 }], {
      duration,
      delay,
      easing,
      fill: "forwards",
    });
  }

  hideDisabledMessage() {
    window.clearTimeout(this.delayBeforeHidingDisabledMessage);
    this.delayBeforeHidingDisabledMessage = setTimeout(() => {
      this.disabledMessageValue = {
        x: null,
        y: null,
        visible: false,
      };
    }, 250);
  }

  hideUsers() {
    this.usersVisibleValue = false;
  }

  pan(e) {
    if (!this.panningState.isPanning) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const deltaX = (e.clientX - this.panningState.x) * this.panTrackingSpeed;
    const deltaY = (e.clientY - this.panningState.y) * this.panTrackingSpeed;
    const { x, y } = this.clampXY({
      x: this.transform.x + deltaX,
      y: this.transform.y + deltaY,
    });

    this.panningState = {
      passedThreshold:
        Math.abs(this.panningState.startX - e.clientX) > 5 ||
        Math.abs(this.panningState.startY - e.clientY) > 5,
      isPanning: true,
      isInterialPanning: false,
      x: e.clientX,
      y: e.clientY,
      startX: this.panningState.startX,
      startY: this.panningState.startY,
      velocityX: deltaX,
      velocityY: deltaY,
    };
    return this.updateTransform({
      x,
      y,
      duration: 0,
      delay: 0,
    });
  }

  preventLinkClickIfPanning(e) {
    if (this.panningState.passedThreshold) {
      e.preventDefault();
    }

    this.panningState = {
      isPanning: false,
      passedThreshold: false,
      x: undefined,
      y: undefined,
      startX: undefined,
      startY: undefined,
    };
  }

  preventScroll(e) {
    e.preventDefault();
  }

  recalculateDimensions() {
    this._needsLayoutRecalc = true;
  }

  scrollToGridCell({ column, row, ...args }) {
    const columnInt = parseInt(column);
    const rowInt = parseInt(row);

    if (
      typeof columnInt !== "number" ||
      typeof rowInt !== "number" ||
      Number.isNaN(columnInt) ||
      Number.isNaN(rowInt)
    ) {
      return Promise.reject();
    }

    return this.scrollToPoint({
      x: (columnInt - 0.5) / 58,
      y: (rowInt - 0.5) / 38,
      ...args,
    });
  }

  // TODO: duration should be a factor of the distance between the current position and the requested position. i.e. make it a constant speed, not a constant duration.
  scrollToMiniMapPosition(e) {
    const { clientX, clientY } = e;
    const rect = this.miniMapTarget.getBoundingClientRect();
    const x = (clientX - rect.left) / rect.width;
    const y = (clientY - rect.top) / rect.height;
    return this.scrollToPoint({ x, y, duration: 150 });
  }

  scrollToPoint({ x, y, ...args } = {}) {
    const transformerRect = this.transformerTarget.getBoundingClientRect();
    const translateX =
      (x * transformerRect.width - this.dimensions.sizer.width / 2) * -1;
    const translateY =
      (y * transformerRect.height - this.dimensions.sizer.height / 2) * -1;
    return this.updateTransform({
      x: translateX,
      y: translateY,
      ...args,
    });
  }

  scrollToStartingPoint(args) {
    return this.scrollToPoint({
      x: this.startingPoint.x,
      y: this.startingPoint.y,
      ...args,
    });
  }

  showControls({ duration = 300, delay = 0, easing = "linear" } = {}) {
    this.datasetControlsTarget.animate([{ opacity: 1 }], {
      duration,
      delay,
      easing,
      fill: "forwards",
    });
  }

  showDisabledMessage(event) {
    window.clearTimeout(this.delayBeforeHidingDisabledMessage);
    this.disabledMessageValue = {
      visible: true,
      x: event.clientX,
      y: event.clientY,
    };
  }

  showUsers() {
    this.usersVisibleValue = true;
  }

  shuffle() {
    const users = this.nextPageOfUsers;
    this.usersValue = users;
    this.usersAlreadyShown = this.usersAlreadyShown.concat(users);
  }

  startPanning(e) {
    if (this.isZoomedOut) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.panningState = {
      isPanning: true,
      isInterialPanning: false,
      passedThreshold: false,
      x: e.clientX,
      y: e.clientY,
      startX: e.clientX,
      startY: e.clientY,
      velocityX: 0,
      velocityY: 0,
    };
    this.element.classList.add("map--panning");
  }

  stopPanning() {
    if (!this.panningState.isPanning) {
      return;
    }

    this.panningState = {
      ...this.panningState,
      isPanning: false,
      isInterialPanning: true,
    };
    let { velocityX, velocityY } = this.panningState;
    const drag = 0.9;
    const update = () => {
      velocityX *= drag;
      velocityY *= drag;
      const { x, y } = this.clampXY({
        x: this.transform.x + velocityX,
        y: this.transform.y + velocityY,
      });
      this.updateTransform({
        x,
        y,
        duration: 0,
      });
      if (
        (Math.abs(velocityX) > 0 || Math.abs(velocityY) > 0) &&
        this.panningState.isInterialPanning
      ) {
        requestAnimationFrame(update);
      } else {
        this.panningState = {
          ...this.panningState,
          isInterialPanning: false,
        };
      }
    };
    requestAnimationFrame(update);
    this.element.classList.remove("map--panning");
  }

  toggleDisabledMessage(event) {
    if (this.disabledMessageValue.visible) {
      this.hideDisabledMessage();
    } else {
      this.showDisabledMessage(event);
    }
  }

  unsetZoom() {
    this.transformerTarget.style.removeProperty("transform");
    this.transformerTarget.getAnimations().forEach((animation) => {
      animation.cancel();
    });
  }

  updateTransform({
    x,
    y,
    scale,
    duration = 0,
    delay = 0,
    easing = "ease-in-out",
  }) {
    const previousTransform = Object.assign({}, this.transform);
    this.transform = {
      x: x ?? previousTransform.x,
      y: y ?? previousTransform.y,
      scale: scale ?? previousTransform.scale,
    };
    const transformString = `translate3d(${this.transform.x}px, ${this.transform.y}px, 0) scale(${this.transform.scale})`;
    return Promise.all([
      this.transformerTarget.animate(
        [
          {
            transform: transformString,
          },
        ],
        {
          duration,
          delay,
          easing,
          fill: "forwards",
        }
      ).finished,
      this.adjustMiniMapZoom({ to: { x, y }, duration, delay, easing }),
    ]);
  }

  /*
    Zoom in on the map, specifically focusing on New York, 
    so that the map is full-scale and scrollable.

    NOTE: This method is called by MapAnimationController#animate
    As such, it needs to return a Promise, and that in turn prevents us
    from using Stimulus values to handle a `zoom` state. 
  */
  zoomIn({ duration = 1000, delay = 0, easing = "ease-in-out" } = {}) {
    if (!this.isZoomedOut) {
      return Promise.reject();
    }
    this.isZoomedOut = false;

    const scale = 1;
    const x =
      this.dimensions.transformer.width * this.startingPoint.x * -1 +
      this.dimensions.sizer.width / 2;
    const y =
      this.dimensions.transformer.height * this.startingPoint.y * -1 +
      this.dimensions.sizer.height / 2;
    const scaleAnimation = this.updateTransform({
      x,
      y,
      scale,
      duration,
      delay,
      easing,
    });

    const circleFillAnimation = this.svgTarget.animate(
      [{ fill: "currentColor" }, { fill: "transparent" }],
      { duration, delay, easing, fill: "forwards" }
    ).finished;

    return Promise.all([scaleAnimation, circleFillAnimation]).then(() => {
      return this.showUsers();
    });
  }

  /*
    Zoom out on the map, so that the whole thing fits inside the
    containing element.

    NOTE: This method is called by MapAnimationController#animate
    As such, it needs to return a Promise, and that in turn prevents us
    from using Stimulus values to handle a `zoom` state. 
  */
  zoomOut({ duration = 1000, delay = 0, easing = "ease-in-out" } = {}) {
    if (this.isZoomedOut) {
      return Promise.reject();
    }

    this.isZoomedOut = true;

    // Firstly, the map users don't look good when they're scaled down, so we hide them.
    this.hideUsers();

    // Scale the canvas down so that the whole thing fits inside the viewport.
    // We scale the element from the "starting point" (i.e. New York) so that
    // later when we remove the scale, the map zooms in on the the place we wanna see.
    const transformerRect = this.transformerTarget.getBoundingClientRect();
    const scrollerAspectRatio =
      this.dimensions.sizer.width / this.dimensions.sizer.height;
    const scalerAspectRatio = transformerRect.width / transformerRect.height;
    const matchToWidth = scrollerAspectRatio <= scalerAspectRatio;
    const scaleDifference = matchToWidth
      ? this.dimensions.sizer.width / transformerRect.width
      : this.dimensions.sizer.height / transformerRect.height;
    const wouldBeRect = {
      width: transformerRect.width * scaleDifference,
      height: transformerRect.height * scaleDifference,
      top: 0,
      left: 0,
      right:
        this.dimensions.sizer.width - transformerRect.width * scaleDifference,
      bottom:
        this.dimensions.sizer.height - transformerRect.height * scaleDifference,
    };
    const translateX = wouldBeRect.right / 2;
    const translateY = wouldBeRect.bottom / 2;

    const scaleAnimation = this.updateTransform({
      scale: scaleDifference,
      x: translateX,
      y: translateY,
      duration,
      delay,
      easing,
    });
    const circleFillAnimation = this.svgTarget.animate(
      [{ fill: "transparent" }, { fill: "currentColor" }],
      { duration, delay, easing, fill: "forwards" }
    ).finished;

    return Promise.all([scaleAnimation, circleFillAnimation]);
  }
  /*
  :::::::::::::::::::::::::
  :: GETTERS AND SETTERS ::
  :::::::::::::::::::::::::
  */

  get dimensions() {
    if (this._needsLayoutRecalc || !this._dimensions) {
      const sizerRect = this.sizerTarget.getBoundingClientRect();
      const transformerRect = this.transformerTarget.getBoundingClientRect();
      this._dimensions = {};
      this._dimensions.sizer = {
        width: sizerRect.width,
        height: sizerRect.height,
      };
      this._dimensions.transformer = {
        width: transformerRect.width / this.transform.scale,
        height: transformerRect.height / this.transform.scale,
      };
      this._needsLayoutRecalc = false;
    }

    return this._dimensions;
  }

  /*
    Per page of data... 
    * Get 30 users
    * Avoid repeating users as long as possible
    * If no more users, repeat users
    * Never all two users with the same `map_location` property on the same page

    NOTE:
    This method does not affect any of the instance's state. You should update
    `this.usersAlreadyShown` yourself.

    NOTE:
    This is a very naive implementation. It's not very efficient and it's not
    very random. It's also not very good at avoiding users with the same
    `map_location` property on the same page. It's just a quick and dirty
    implementation that works well enough for now.
    When the data set grows to be very large, we'll need to implement a more
    efficient and more random algorithm.
  */
  get nextPageOfUsers() {
    if (this.users.length < this.perPage) {
      return this.users;
    }

    const users = [];
    const userLocations = [];

    const pickRandomUserFromSet = (set = this.users) => {
      const user = set[Math.floor(Math.random() * set.length)];
      const locationAsString = JSON.stringify(user.map_location);
      users.push(user);
      userLocations.push(locationAsString);
    };

    while (users.length < this.perPage) {
      // Users we haven't shown yet
      const availableUsers = this.users.filter(
        (user) =>
          !this.usersAlreadyShown.includes(user) && !users.includes(user)
      );

      // Users who are from a different location than the users on this page
      const usersFromUniqueLocations = this.users.filter(
        (user) => !userLocations.includes(JSON.stringify(user.map_location))
      );
      // Users who haven't shown yet and who are from a different location than
      // the users on this page
      const availableUsersFromUniqueLocations = availableUsers.filter((user) =>
        usersFromUniqueLocations.includes(user)
      );

      if (Boolean(availableUsersFromUniqueLocations.length)) {
        pickRandomUserFromSet(availableUsersFromUniqueLocations);
      } else if (Boolean(usersFromUniqueLocations.length)) {
        pickRandomUserFromSet(usersFromUniqueLocations);
      } else {
        break;
      }
    }

    return users;
  }

  /*
    The speed at which the map pans when the user drags the map.
    We want this to be higher on smaller screens (meaning the map
    moves more for a given mouse movement), since it would otherwise
    take a long time to get from one side of the map to the other.
  */
  get panTrackingSpeed() {
    if (!this._panTrackingSpeed) {
      if (window.innerWidth > 1600) {
        this._panTrackingSpeed = 1;
      } else if (window.innerWidth > 1200) {
        this._panTrackingSpeed = 1;
      } else if (window.innerWidth > 600) {
        this._panTrackingSpeed = 1;
      } else if (window.innerWidth > 360) {
        this._panTrackingSpeed = 2;
      } else {
        this._panTrackingSpeed = 2;
      }
    }

    return this._panTrackingSpeed;
  }

  /*
  :::::::::::::::::::::::::::
  :: VALUE CHANGE HANDLERS ::
  :::::::::::::::::::::::::::
  */

  usersValueChanged(users) {
    this.canvasTarget.innerHTML = users
      .map(
        (user) =>
          `<a
            href="${user.participant_page_path}"
            class="map-user"
            style="
              grid-column-start: ${user.map_location.x};
              grid-column-span: 1;
              grid-row-start: ${user.map_location.y};
              grid-row-span: 1;
              color: var(--${user.focus_color || "white"});
            "
            data-map-target="mapUser"
            data-action="click->map#preventLinkClickIfPanning"
          >
            <div class="map-user__image">
              <div style="background-color: var(--${
                user.focus_color || "white"
              })">
                ${
                  user.headshot
                    ? `<img src="${user.headshot}" />`
                    : `<div class="map-user__placeholder-image"></div>`
                }
              </div>
            </div>
            <div class="map-user__details">
              <div class="map-user__name">${user.name}</div>
              <div class="map-user__location">${user.location || ""}</div>
              <div class="map-user__focus">${user.focus_title || ""}</div>
            </div>
          </a>`
      )
      .join("");
    this.miniMapTarget.innerHTML = users
      .map(
        (user, index) =>
          `<div
            class="mini-map-user"
            style="
              grid-column-start: ${user.map_location.x};
              grid-column-span: 1;
              grid-row-start: ${user.map_location.y};
              grid-row-span: 1;
            "
          >
            <div
              class="mini-map-user__inner"
              style="background-color: var(--${user.focus_color || "white"});"
            ></div>
          </div>`
      )
      .join("");

    if (this.hasVisibleUsersCountLabelTarget) {
      this.visibleUsersCountLabelTarget.textContent = users.length;
    }
  }

  disabledMessageValueChanged(value, previousValue) {
    const fadeIn = () => {
      this.disabledMessageTarget.style.setProperty("display", "block");
      this.disabledMessageTarget.animate([{ opacity: 1 }], {
        duration: 250,
        fill: "forwards",
      });
    };
    const fadeOut = () => {
      this.disabledMessageTarget
        .animate([{ opacity: 0 }], {
          duration: 250,
          fill: "forwards",
        })
        .finished.then(() => {
          this.disabledMessageTarget.style.setProperty("display", "none");
        });
    };
    const updatePosition = () => {
      this.disabledMessageTarget.style.setProperty("left", `${value.x}px`);
      this.disabledMessageTarget.style.setProperty("top", `${value.y}px`);
    };

    const visibilityChanged = value.visible !== previousValue.visible;
    if (visibilityChanged && !value.visible) {
      fadeOut();
    } else if (visibilityChanged && value.visible) {
      updatePosition();
      fadeIn();
    } else {
      updatePosition();
    }
  }

  /*
  :::::::::::::::::::::
  :: PRIVATE METHODS ::
  :::::::::::::::::::::
  */

  _testPickingAlgorithm({ iterationCount = 10 } = {}) {
    const startTime = new Date().getTime();
    const log = [];
    const tests = {
      "All pages have correct number of users": true,
      "All pages have correct number of unique locations": true,
      "No pages have the same user twice": true,
      "Repeats occur only after all users have been shown": true,
    };

    log.push(`Testing picking algorithm with ${iterationCount} iterations`);
    Array.from({ length: iterationCount }).forEach((_, pageNumber) => {
      const pageStartTime = new Date().getTime();
      log.push(`Getting page #${pageNumber}`);
      const page = this.nextPageOfUsers;
      const userCount = page.length;
      const uniqueLocationCount = new Set(
        page.map((u) => JSON.stringify(u.map_location))
      ).size;
      const pageRepeatUserCount = page.length - new Set(page).size;
      const allTimeRepeatUserCount = page.filter((u) => {
        return this.usersAlreadyShown.includes(u);
      }).length;
      this.usersAlreadyShown = this.usersAlreadyShown.concat(page);

      // How many users are on this page?
      log.push(`  User count: ${userCount}`);
      if (userCount !== this.perPage) {
        tests["All pages have correct number of users"] = false;
      }

      // How many unique locations are on this page? Do any users have the same location?
      log.push(`  Unique location count: ${uniqueLocationCount}`);
      if (uniqueLocationCount !== page.length) {
        tests["All pages have correct number of unique locations"] = false;
      }

      // Does this page include duplicate users?
      log.push(`  Repeat users in this page count: ${pageRepeatUserCount}`);
      if (pageRepeatUserCount !== 0) {
        tests["No pages have the same user twice"] = false;
      }

      // Does this page include users who have already been shown, ever?
      log.push(`  Repeat (of all time) count: ${allTimeRepeatUserCount}`);
      if (
        allTimeRepeatUserCount !== 0 &&
        (pageNumber + 1) * this.perPage < this.users.length
      ) {
        tests["Repeats occur only after all users have been shown"] = false;
      }

      const pageEndTime = new Date().getTime();
      log.push(`  Time spent getting users: ${pageEndTime - pageStartTime}ms`);
    });

    const allTestsPass = Object.values(tests).every((v) => v);
    const endTime = new Date().getTime();
    const timeSpent = `${endTime - startTime}ms`;

    log.push(`Total time spent: ${endTime - startTime}`);

    return { result: allTestsPass, tests, log, timeSpent };
  }
}
