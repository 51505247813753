import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus";

const application = Application.start();
window.Stimulus = application;

import CarouselController from "./controllers/carousel_controller";
application.register("carousel", CarouselController);

import ExcerptModuleController from "./controllers/excerpt_module_controller";
application.register("excerpt-module", ExcerptModuleController);

import QuestionModuleController from "./controllers/question_module_controller";
application.register("question-module", QuestionModuleController);

import SvgController from "./controllers/svg_controller";
application.register("svg", SvgController);

import VideoController from "./controllers/video_controller";
application.register("video", VideoController);

import MapAnimationComponentController from "../components/map_animation_component_controller";
application.register("map-animation-component", MapAnimationComponentController);

import MapController from "../components/map_controller";
application.register("map", MapController);

import MapLocationEditorComponentController from "../components/map_location_editor_component_controller";
application.register("map-location-editor-component", MapLocationEditorComponentController);
